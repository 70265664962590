export default {
  message: {
    hello: 'Hi',
    welcome: 'Welcome'
  },
  lottery: {
    title: 'PK Rank',
    period: 'Period',
    balance: 'Balance',
    currency: '$ RM',
    tips: {
      playTip: 'Play Tips',
      gameRules: 'Game Rules',
      gameTip: 'Game Tips',
      winDesc: 'Win Description',
      betExample: 'Bet Example',
      tipContent: 'Choose options and values from the number list',
      winContent: 'The sum of three tickets is 11-18 for big, and 3-10 for small',
      exampleContent: 'Betting plan: small, tickets: 123, meaning small'
    },
    betting: {
      orderList: 'Order List',
      balance: 'Balance',
      submit: 'Submit',
      currentSelect: 'Current Selection',
      notSelected: 'Not Selected',
      perAmount: 'Bet Amount',
      enterAmount: 'Enter Amount',
      total: 'Total',
      bet: 'Bet',
      amount: 'Amount'
    },
    order: {
      title: 'Order Details',
      detail: '{bet} bets x {amount}$ RM = {total}$ RM',
      clearOrder: 'Clear Order',
      confirmSubmit: 'Confirm Submit'
    },
    toast: {
      amountError: 'Amount Error!',
      selectNumber: 'Please select a number!',
      enterAmount: 'Please enter the amount!',
      balanceInsufficient: 'Insufficient balance, please contact customer service for recharge!',
      winningNumber: 'Draw successful, period number: {expect}',
      contactAdmin: 'Please contact the administrator!',
      refreshSuccess: 'Refresh successful'
    },
    refresh: {
      loading: 'Loading...',
      pulling: 'Pull down to refresh...',
      loosing: 'Release to refresh'
    },
    history: {
      period: 'Period',
      result: 'Tickets',
      type: {
        sexy: 'Sexy',
        sweet: 'Sweet',
        mature: 'Mature',
        pure: 'Pure'
      }
    },
    types: {
      "Sexy": "Sexy",
      "Sweet": "Sweet", 
      "Mature": "Mature",
      "Pure": "Pure"
    }
  },
  footer: {
    home: 'SKYClub',
    game: 'Game',
    choose: 'Beauty',
    video: 'Video',
    mine: 'Mine'
  },
  login: {
    title: 'Login',
    username: 'Please enter your username',
    password: 'Please enter your password',
    forgotPassword: 'Forgot password?',
    noAccount: 'No account? Register now',
    loginButton: 'Login',
    usernameRequired: 'Please enter your username!',
    passwordRequired: 'Please enter your password!'
  },
  register: {
    title: 'Register',
    username: 'Please enter your username (6-12 letters or numbers)',
    password: 'Please enter your password (6-12 letters or numbers)',
    inviteCode: 'Please enter the invitation code',
    agreement: 'I have read and agree to the terms of the account opening agreement',
    registerButton: 'Register',
    inviteRequired: 'Please enter the invitation code!',
    agreementRequired: 'Please check the account opening agreement below!'
  },
  home: {
    hotGame: {
      title: 'Recommended Games',
      more: 'View More'
    },
    hotRecommend: {
      rankTitle: 'Hot Rank',
      recommendTitle: 'Hot Recommend',
      more: 'View More',
      playCount: 'Play:'
    },
    refresh: {
      loading: 'Loading...',
      pulling: 'Pull down to refresh...',
      loosing: 'Release to refresh'
    },
    toast: {
      refreshSuccess: 'Refresh successful'
    }
  },
  game: {
    title: 'Game Hall',
    sidebar: {
      all: 'All'
    },
    refresh: {
      loading: 'Loading...',
      pulling: 'Pull down to refresh...',
      loosing: 'Release to refresh'
    },
    toast: {
      refreshSuccess: 'Refresh successful'
    }
  },
  choose: {
    title: 'Beauty',
    tabs: {
      cityBattle: 'City Battle',
      processPrice: 'Process Price'
    },
    cityDesc: 'This platform is the most authentic outer circle + business companion tour + local passion in the whole network. In order to protect the personal privacy of each user, customers can only register through contacting the receptionist or platform senior members for real name recommendation.',
    process: {
      title1: 'What resources are there?',
      content1: 'Internet celebrities, models, elder sisters',
      title2: 'Service range?',
      content2: 'Local free dating in the same city, all over the country, in the first and second tier cities of China, and in the third tier cities can also contact the receptionist for appointment.',
      content3: 'This platform is the most authentic outer circle + business companion tour + local passion in the whole network. In order to protect the personal privacy of each user, customers can only register through contacting the receptionist or platform senior members for real name recommendation.'
    },
    list: {
      refresh: {
        loading: 'Loading...',
        pulling: 'Pull down to refresh...',
        loosing: 'Release to refresh',
        success: 'Refresh successful!'
      }
    },
    profile: {
      book: 'Booking',
      toast: 'Please contact the receptionist or administrator'
    }
  },
  video: {
    title: 'Video Hall',
    list: {
      refresh: {
        loading: 'Loading...',
        pulling: 'Pull down to refresh...',
        loosing: 'Release to refresh',
        success: 'Refresh successful',
        noMore: 'No more'
      },
      playCount: 'Play:'
    },
    player: {
      playCount: 'Times played',
      recommend: 'Hot Recommend',
      offline: 'Account offline',
      recharge: 'Please recharge before watching the video!',
      title: 'Video Play',
      back: 'Back'
    }
  },
  mine: {
    title: 'Mine',
    finance: {
      recharge: 'Recharge',
      withdraw: 'Withdraw',
      rechargeHint: 'Please contact customer service for recharge'
    },
    wallet: {
      title: 'My Wallet',
      details: 'Details',
      balance: 'Balance'
    },
    menu: {
      report: 'Personal Report',
      account: 'Account Details', 
      gameRecord: 'PK Record',
      profile: 'Mine',
      notice: 'Notice',
      service: 'Customer Service',
      accountTip: 'Please complete the task list before entering'
    },
    setting: {
      title: 'Setting Center',
      items: {
        profile: 'Personal Information',
        loginPwd: 'Login Password',
        payPwd: 'Fund Password'
      },
      status: {
        set: 'Set',
        notSet: 'Not Set'
      },
      button: {
        logout: 'Logout'
      },
      toast: {
        payPwdTip: 'Fund password has been set, please contact customer service if you need to modify'
      },
      loginPwd: {
        title: 'Modify Login Password',
        save: 'Save',
        oldPassword: 'Original Password',
        oldPasswordPlaceholder: 'Please enter the original password',
        newPassword: 'New Password',
        newPasswordPlaceholder: 'Please enter the new password',
        confirmPassword: 'Confirm Password',
        confirmPasswordPlaceholder: 'Please enter the new password again',
        enterOld: 'Please enter the original password',
        enterNew: 'Please enter the new password',
        confirmNew: 'Please enter the new password again',
        notMatch: 'The passwords entered twice do not match',
        toast: {
          success: 'Modification successful',
          error: 'Modification failed'
        }
      }
    },
    profile: {
      title: 'Personal Information',
      avatar: 'Avatar',
      name: {
        label: 'Real Name',
        notSet: 'Not Set',
        required: 'Please enter your name',
        placeholder: 'Please enter your real name',
        notice: 'For account security, please ensure that the real name is consistent with the name on the bound bank card',
        toast: {
          duplicate: 'Please do not repeat the setting!',
          success: 'Setting successful'
        }
      },
      gender: {
        label: 'Gender',
        unknown: 'Unknown',
        male: 'Male',
        female: 'Female',
        title: 'Set Gender',
        save: 'Save',
        toast: {
          success: 'Setting successful',
          duplicate: 'Please do not repeat the setting'
        }
      },
      bindInfo: {
        label: 'Binding Information',
        bound: 'Bound',
        notBound: 'Not Bound',
        title: 'Binding Information'
      },
      toast: {
        headerSuccess: 'Avatar modification successful',
        headerError: 'Avatar modification failed'
      },
      headerImg: {
        title: 'Select Avatar',
        confirm: 'Confirm',
        cancel: 'Cancel'
      }
    },
    withdraw: {
      title: 'Withdraw',
      amount: 'Withdraw Amount',
      all: 'All',
      balance: 'Balance', 
      button: 'Withdraw',
      recordBtn: 'Withdraw Record',
      limitInfo: {
        title: 'Quota Description',
        tip1: 'Single quota {min}, {max}',
        tip2: 'Daily maximum withdrawal {num}',
        tip3: 'Arrival time: Generally arrives within 5 minutes, fastest within 2 minutes',
        min: 'Minimum:',
        max: 'Maximum:',
        times: 'Times'
      },
      toast: {
        enterAmount: 'Please enter the correct amount',
        error: 'Withdrawal failed',
        success: 'Withdrawal successful'
      },
      record: {
        title: 'Withdraw Record',
        empty: 'No record yet',
        status: {
          success: 'Success',
          pending: 'Processing',
          failed: 'Failed'
        },
        amount: 'Amount',
        desc: 'Description',
        submitTime: 'Submit Time',
        auditTime: 'Audit Time',
        refresh: {
          loading: 'Loading...',
          pulling: 'Pull down to refresh...',
          loosing: 'Release to refresh',
          success: 'Refresh successful'
        }
      }
    },
    bank: {
      title: 'Receipt Information',
      addCard: 'Add Receipt Card',
      info: {
        bankInfo: 'Bank Information',
        name: 'Name',
        cardNumber: 'Card Number'
      },
      tip: 'Note: Binding large commercial banks, please contact online customer service for modification',
      bind: {
        title: 'Bind Bank Card',
        tip: 'Please bind large commercial banks',
        name: 'Real Name',
        namePlaceholder: 'Please enter your real name',
        cardNumber: 'Card Number',
        cardNumberPlaceholder: 'Please enter the actual card number',
        bank: 'Opening Bank',
        bankPlaceholder: 'Please select the bank',
        button: 'Bind Bank Card',
        notice: 'Dear user, in order to ensure the safety of your funds, please bind your real name and withdrawal password. If the name does not match the account name, you will not be able to withdraw',
        toast: {
          enterName: 'Please enter your name!',
          enterCardNumber: 'Please enter the card number!',
          selectBank: 'Please select the bank',
          success: 'Binding successful',
          enterComplete: 'Please enter complete information',
          setBankFirst: 'Please set up the bank card first'
        },
        picker: {
          confirm: 'Confirm',
          cancel: 'Cancel'
        },
        payPassword: {
          title: 'Please enter the fund password',
          confirmTitle: 'Please enter the fund password again'
        }
      }
    },
    report: {
      title: 'Personal Report',
      profit: {
        title: 'Profit Amount'
      },
      details: {
        title: 'Profit Calculation Formula: Remaining Balance - PK Amount',
        betAmount: 'PK Amount',
        validAmount: 'Recharge Amount',
        profit: 'Withdraw Amount'
      },
      toast: {
        error: 'Failed to get data'
      }
    },
    gameRecord: {
      title: 'Game Record',
      empty: 'No record yet',
      list: {
        time: 'Time',
        gameName: 'Game Name',
        betAmount: 'PK Amount',
        profit: 'Profit and Loss',
        status: {
          win: 'Win',
          lose: 'Lose'
        },
        details: 'Details',
        sexy: 'Sexy',
        sweet: 'Sweet',
        mature: 'Mature',
        pure: 'Pure',
        paymentTime: 'Payment Time'
      },
      refresh: {
        loading: 'Loading...',
        pulling: 'Pull down to refresh...',
        loosing: 'Release to refresh',
        success: 'Refresh successful'
      },
      detail: {
        title: 'Game Details',
        period: 'Period',
        time: 'Time',
        betAmount: 'PK Amount',
        betType: 'Bet Type',
        result: 'Tickets',
        status: {
          title: 'Status',
          win: 'Winning',
          lose: 'Not Winning',
          pending: 'Pending Draw'
        },
        winAmount: 'PK Amount',
        profit: 'Profit and Loss',
        odds: 'Odds',
        type: {
          sexy: 'Sexy',
          sweet: 'Sweet',
          mature: 'Mature',
          pure: 'Pure'
        }
      },
      toast: {
        error: 'Failed to get data'
      },
      status: {
        pending: 'Pending Draw',
        win: 'Drawn',
        lose: 'Not Drawn'
      }
    },
    notice: {
      title: 'System Notice',
      empty: 'No notice yet',
      refresh: {
        loading: 'Loading...',
        pulling: 'Pull down to refresh...',
        loosing: 'Release to refresh',
        success: 'Refresh successful'
      }
    },
    service: {
      title: 'Online Customer Service',
      contact: 'Contact',
      available: '7x24 hours online service', 
      defaultName: 'Peach Entertainment',
      disabled: 'Function not enabled'
    },
    loginHint: 'Log in to enjoy more services!'
  }
} 