<template>
  <div class="mine page">
    <div class="page-bg"></div>
    <div class="wrapper">
      <van-pull-refresh 
        :loading-text="$t('home.refresh.loading')"
        :pulling-text="$t('home.refresh.pulling')"
        :loosing-text="$t('home.refresh.loosing')"
        v-model="isLoading" 
        @refresh="onRefresh"
      >
        <div class="header">
          <van-nav-bar class="nav-bar">
            <template #right>
              <div class="nav-right">
                <div class="lang-switch" @click="showLangPopup">
                  <span>{{ currentLang === 'zh' ? '中文' : 'English' }}</span>
                  <van-icon name="arrow-down" color="#fff" style="margin-left: 5px;" />
                </div>
                <van-icon name="setting-o" @click="showSetting()" color="#fff"/>
              </div>
            </template>
          </van-nav-bar>
          <div class="user-wrapper" @click="doLogin()">
            <van-image round class="user_img" :src="userInfo.header_img">
              <template v-slot:loading>
                <van-loading type="spinner"/>
              </template>
            </van-image>
            <div class="login-content">
              <p class="login-btn">{{userInfo.username}}</p>
              <p class="login-label">{{userInfo.ip}}</p>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="finance">
            <div class="finance-item" @click="doPay()">
              <van-icon class="icon" name="peer-pay" />
              <span class="text">{{ $t('mine.finance.recharge') }}</span>
            </div>
            <div class="line"></div>
            <div class="finance-item" @click="doWithdrawal()">
              <van-icon class="icon" name="idcard" />
              <span class="text">{{ $t('mine.finance.withdraw') }}</span>
            </div>
          </div>
          <div v-if="userInfo.money" class="wallet">
            <div class="part-1 van-hairline--bottom">
              <p class="flex-1 font-28 font-primary-color">{{ $t('mine.wallet.title') }}</p>
              <span class="font-28 font-gray">{{ $t('mine.wallet.details') }}</span>
              <van-icon class="font-gray" style="font-size: 28px" name="arrow" />
            </div>
            <div class="part-2">
              <p class="balance van-ellipsis">{{userInfo.money}}</p>
              <span class="font-28 font-gray">{{ $t('mine.wallet.balance') }}</span>
              <div class="refresh-btn" @click="refresh()"><van-icon name="replay" /></div>
            </div>
          </div>
          <div :style="{ marginTop: menu_top +'px'}" class="menu">
            <div class="menu-item" @click="$router.push({path:'/Personalreport'});">
              <van-image class="menu-item-icon" src="img/mine/baobiao.svg">
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t('mine.menu.report') }}</span>
            </div>
            <div class="menu-item" @click="exit()">
              <van-image class="menu-item-icon" src="img/mine/mingxi.svg">
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t('mine.menu.account') }}</span>
            </div>
            <div class="menu-item" @click="$router.push({path:'/GameRecord'});">
              <van-image class="menu-item-icon" src="img/mine/youxi.svg">
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t('mine.menu.gameRecord') }}</span>
            </div>
            <div class="menu-item" @click="$router.push({path:'/Infomation'});">
              <van-image class="menu-item-icon" src="img/mine/user.svg">
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t('mine.menu.profile') }}</span>
            </div>
            <div class="menu-item" @click="$router.push({path:'/Notice'});">
              <van-image class="menu-item-icon" src="img/mine/notice.svg">
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t('mine.menu.notice') }}</span>
            </div>
            <div class="menu-item" @click="$router.push({path:'/ServiceOnline'});">
              <van-image class="menu-item-icon" src="img/mine/kefu.svg">
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t('mine.menu.service') }}</span>
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>

    <!-- 语言选择弹出框 -->
    <van-popup
      v-model="showLangSelect"
      position="top"
      :style="{ top: '46px', width: '160px', right: '5px', left: 'auto' }"
      class="lang-popup"
    >
      <div class="lang-list">
        <div 
          class="lang-item" 
          v-for="lang in languages" 
          :key="lang.value"
          @click="selectLang(lang.value)"
          :class="{ active: currentLang === lang.value }"
        >
          {{ lang.label }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo:{
      },
      menu_top:40,
      isLoading: false,
      currentLang: 'en',
      showLangSelect: false,
      languages: [
        { label: 'English', value: 'en' }
      ]
    };
  },
  methods: {
    refresh(){
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if(localStorage.getItem('token')){
          this.$toast(this.$t('home.toast.refreshSuccess'));
        }else{
          this.$router.push({path:'/Login'})
        }
      }, 500);
    },
    exit(){
      this.$toast(this.$t('mine.menu.accountTip'));
    },
    showSetting() {
      if(localStorage.getItem('token')){
        this.$router.push({path:'/Setting'})
      }else{
        this.$router.push({path:'/Login'})
      }
    },
    toNotice(){
      if(localStorage.getItem('token')){
        this.$router.push({path:'/Notice'})
      }else{
        this.$router.push({path:'/Login'})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if(localStorage.getItem('token')){
            this.getUserInfo();
            this.$toast(this.$t('home.toast.refreshSuccess'));
        }else{
            this.$router.push({path:'/Login'})
        }
      }, 500);
    },
    doLogin(){
        if(localStorage.getItem('token')){
            this.$router.push({path:'/Infomation'});
        }else {
            this.$router.push({path:'/Login'})
        }
    },
    doPay(){
      this.$toast(this.$t('mine.finance.rechargeHint'));
      return false;
    },
    doWithdrawal(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
          if(res.data.is_bank){
            this.$router.push("withdraw");
          }else {
            this.$router.push("Setbank");
            this.$toast.fail(this.$t('mine.bank.bind.toast.setBankFirst'));
          }
      })
    },
    toService(){
      if(this.$store.getters.getBaseInfo.iskefu == 1){
        this.$router.push("ServiceOnline");
      }else {
        this.$toast.fail(this.$t('mine.service.disabled'));
      }
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
            this.userInfo = res.data;
            this.menu_top = 15;
            if(this.userInfo.status !== 1){
              this.$toast(this.$t('video.player.offline'));
              localStorage.clear()
              this.$router.push({path:'/Login'})
            }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    showLangPopup() {
      this.showLangSelect = true;
    },
    selectLang(lang) {
      this.currentLang = lang;
      this.$i18n.locale = lang;
      localStorage.setItem('language', lang);
      this.showLangSelect = false;
    }
  },
  created() {
    if(localStorage.getItem('token')){
        this.getUserInfo();
    }else {
      this.userInfo.username = this.$t('login.title') + ' / ' + this.$t('register.title');
      this.userInfo.ip = this.$t('mine.loginHint');
      this.userInfo.header_img = "img/mine/avatar.png";
    }
    this.currentLang = localStorage.getItem('language') || this.$i18n.locale;
  }
};
</script>

<style lang="less" scoped>
.page{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.mine{
  position: relative;
  bottom: 10px;
  background: #f2f2f5;
}
.mine .wrapper{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.nav-bar{
  background: linear-gradient(
      90deg,#7e5678,#e6c3a1);
}
.mine .header{
  background: linear-gradient(
      90deg,#7e5678,#e6c3a1);
  padding-bottom: 100px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0px;
}
.mine .header .van-nav-bar .van-icon {
  font-size: 45px;
}
.mine .header .user-wrapper{
  display: flex;
  align-items: center;
  margin: 0px 40px 0px 40px;

}
.mine .user_img{
  height: 130px;
  width: 130px;
}
::v-deep .van-loading__spinner {
  height: 50px;
  width: 50px;
}
::v-deep .van-image__error-icon {
  font-size: 70px;
}
.mine  .header .user-wrapper .login-content{
  flex: 1;
  margin-left: 30px;
}
.mine  .header .user-wrapper .login-content .login-btn{
  display: inline-block;
  font-size: 40px;
  line-height: 0px;
  color: #fff;
}
.mine .header .user-wrapper .login-content .login-label{
  margin-top: -13px;
  font-size: 28px;
  color: hsla(0,0%,100%,.6);
}
.mine .page-bg{
  height: 500px;
  background: linear-gradient(
      90deg,#7e5678,#e6c3a1);
}
.mine .content{
  position: relative;
  padding: 10px 30px 30px;
  min-height: 500px;
  background-color: #f2f2f5;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #ffffff;
  font-size: 35px;
}

.mine .wrapper .content .finance {
  position: absolute;
  display: flex;
  align-items: center;
  top: -55px;
  left: 30px;
  right: 30px;
  height: 120px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .finance .line{
  width: 3px;
  height: 40px;
  background-color: #ccc;
}
.mine .wrapper .content .finance .finance-item{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.mine .wrapper .content .finance .finance-item .text{
  margin-left: 30px;
  font-size: 30px;
  color: #c24491;
  font-weight: 500;
}
.mine .wrapper .content .finance .finance-item .icon{
  font-size: 50px;
}
.mine .wrapper .content .menu{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .menu .menu-item{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 130px;
}
.mine .wrapper .content .menu .menu-item .menu-item-label{
  font-size: 30px;
  color: #c24491;
  font-weight: 500;
}
.mine .wrapper .content .menu .menu-item .menu-item-icon{
  margin: 25px;
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
}
.mine .wrapper .content .wallet{
  margin-top: 80px;
  padding: 0 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .wallet .part-1{
  display: flex;
  align-items: center;
  height: 100px;
}
.mine .wrapper .content .wallet .font-primary-color{
  color: #c24491;
}
.font-gray {
  color: #c24491;
}
.mine .wrapper .content .wallet .part-2{
  display: flex;
  align-items: center;
  height: 150px;
}
.mine .wrapper .content .wallet .part-2 .balance{
  flex: 1;
  font-size: 60px;
  color: #7e5678;
  font-weight: 700;
}
.mine .wrapper .content .wallet .van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.mine .wrapper .content .wallet .part-2 .refresh-btn{
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 30px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #e6c3a1;
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 15px;
}

.lang-switch {
  color: #fff;
  font-size: 28px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.lang-popup {
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
}

.lang-list {
  .lang-item {
    padding: 24px;
    text-align: center;
    font-size: 28px;
    color: #333;
    border-bottom: 1px solid #f5f5f5;
    
    &:last-child {
      border-bottom: none;
    }
    
    &:active {
      background-color: #f2f3f5;
    }

    &.active {
      color: #7e5678;
    }
  }
}

::v-deep .van-popup {
  background-color: #fff;
  box-shadow: 0 2px 12px rgba(100, 101, 102, 0.12);
}
</style>
