<template>
  <div class="container page">
    <van-nav-bar :title="$t('mine.profile.gender.title')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
      <template #right>
        <span class="nav-right" @click="save()">{{ $t('mine.profile.gender.save') }}</span>
      </template>
    </van-nav-bar>
    <van-radio-group v-model="sex">
      <van-cell-group>
        <van-cell :title="$t('mine.profile.gender.male')" clickable @click="sex = '1'">
          <template #right-icon>
            <van-radio name="1" />
          </template>
        </van-cell>
        <van-cell :title="$t('mine.profile.gender.female')" clickable @click="sex = '2'">
          <template #right-icon>
            <van-radio name="2" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sex: '',
      userInfo: {}
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    save() {
      if(this.userInfo.sex) {
        this.$toast(this.$t('mine.profile.gender.toast.duplicate'));
        return true;
      }
      this.$http({
        method: 'get',
        data: { sex: this.sex },
        url: 'user_set_sex'
      }).then(res => {
        if(res.code === 200) {
          this.getUserInfo();
          this.$toast(this.$t('mine.profile.gender.toast.success'));
        } else if(res.code === 401) {
          this.$toast(this.$t(res.msg));
        }
      })
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if(res.code === 200) {
          this.userInfo = res.data;
          this.sex = res.data.sex;
        } else if(res.code === 401) {
          this.$toast(this.$t(res.msg));
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')) {
      this.$router.push({path:'/Login'})
    } else {
      this.getUserInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 35px;
  line-height: 80px;
}
</style>
