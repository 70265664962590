export default {
  message: {
    hello: '你好',
    welcome: '欢迎使用'
  },
  lottery: {
    title: 'PK打榜',
    period: '期号',
    balance: '余额',
    currency: '$ RM',
    tips: {
      playTip: '玩法提示',
      gameRules: '游戏规则',
      gameTip: '游戏提示',
      winDesc: '中奖说明',
      betExample: '投注示例',
      tipContent: '选择选项和值形式中的号码下注',
      winContent: '三个刷票之和11-18为大，和值3-10为小',
      exampleContent: '投注方案：小，刷票：123，即中小'
    },
    betting: {
      orderList: '明细表',
      balance: '余额',
      submit: '提交',
      currentSelect: '当前选号',
      notSelected: '未选择',
      perAmount: '打榜金额',
      enterAmount: '请输入金额',
      total: '共',
      bet: '注',
      amount: '金额'
    },
    order: {
      title: '下注明单',
      detail: '{bet}注 x {amount}$ RM = {total}$ RM',
      clearOrder: '清空订单',
      confirmSubmit: '确认提交'
    },
    toast: {
      amountError: '金额错误！',
      selectNumber: '请选择号码！',
      enterAmount: '请填写金额！',
      balanceInsufficient: '余额不足，请联系客服充值！',
      winningNumber: '开奖成功，期号：{expect}',
      contactAdmin: '该局请联系管理员！',
      refreshSuccess: '刷新成功'
    },
    refresh: {
      loading: '加载中...',
      pulling: '下拉刷新...',
      loosing: '释放刷新'
    },
    history: {
      period: '期号',
      result: '刷票',
      type: {
        sexy: '性感',
        sweet: '甜美',
        mature: '成熟',
        pure: '清纯'
      }
    },
    types: {
      "Sexy": "性感",
      "Sweet": "甜美", 
      "Mature": "成熟",
      "Pure": "清纯"
    }
  },
  footer: {
    home: '天空传媒',
    game: '游戏',
    choose: '选美',
    video: '视频',
    mine: '我的'
  },
  login: {
    title: '登录',
    username: '请输入用户名',
    password: '请输入密码',
    forgotPassword: '忘记密码?',
    noAccount: '没有账号?立即注册',
    loginButton: '登录',
    usernameRequired: '请输入用户名！',
    passwordRequired: '请输入密码！'
  },
  register: {
    title: '注册',
    username: '请输入用户名(6-12位英文或数字)',
    password: '请输入密码(6-12位英文或数字)',
    inviteCode: '请输入邀请码',
    agreement: '我已阅读并同意开户协议的各项条款',
    registerButton: '注册',
    inviteRequired: '请输入邀请码！',
    agreementRequired: '请勾选下方开户协议！'
  },
  home: {
    hotGame: {
      title: '推荐游戏',
      more: '查看更多'
    },
    hotRecommend: {
      rankTitle: '热门排行',
      recommendTitle: '热门推荐',
      more: '查看更多',
      playCount: '播放:'
    },
    refresh: {
      loading: '加载中...',
      pulling: '下拉刷新...',
      loosing: '释放刷新'
    },
    toast: {
      refreshSuccess: '刷新成功'
    }
  },
  game: {
    title: '游戏大厅',
    sidebar: {
      all: '全部'
    },
    refresh: {
      loading: '加载中...',
      pulling: '下拉刷新...',
      loosing: '释放刷新'
    },
    toast: {
      refreshSuccess: '刷新成功'
    }
  },
  choose: {
    title: '选美',
    tabs: {
      cityBattle: '城市对战',
      processPrice: '流程价格'
    },
    cityDesc: '本平台全网最真实的外围+商务伴游+同城激情，为保障每位用户的个人隐私，客户只能通过联系接待员或平台资深会员实名推荐才能注册。',
    process: {
      title1: '有什么资源？',
      content1: '网红、模特、姐姐妹妹',
      title2: '服务范围？',
      content2: '同城免费约炮，全空降任意地，国内一二线城市本地都有，三线城市也可以联系接待员预约。',
      content3: '本平台全网最真实的外围+商务伴游+同城激情，为保障每位用户的个人隐私，客户只能通过联系接待员或平台资深会员实名推荐才能注册。'
    },
    list: {
      refresh: {
        loading: '加载中...',
        pulling: '下拉刷新...',
        loosing: '释放刷新',
        success: '刷新成功！'
      }
    },
    profile: {
      book: '预约',
      toast: '请联系接待员或管理员'
    }
  },
  video: {
    title: '视频大厅',
    list: {
      refresh: {
        loading: '加载中...',
        pulling: '下拉刷新...',
        loosing: '释放刷新',
        success: '刷新成功',
        noMore: '没有更多了'
      },
      playCount: '播放:'
    },
    player: {
      playCount: '次播放',
      recommend: '热门推荐',
      offline: '账号已下线',
      recharge: '请充值后观看视频！',
      title: '视频播放',
      back: '返回'
    }
  },
  mine: {
    title: '个人中心',
    finance: {
      recharge: '充值',
      withdraw: '提现',
      rechargeHint: '请联系客服充值'
    },
    wallet: {
      title: '我的钱包',
      details: '明细',
      balance: '余额'
    },
    menu: {
      report: '个人报表',
      account: '账户明细', 
      gameRecord: '打榜记录',
      profile: '个人中心',
      notice: '消息公告',
      service: '客服',
      accountTip: '请完成任务列表后再进入'
    },
    setting: {
      title: '设置中心',
      items: {
        profile: '个人资料',
        loginPwd: '登录密码',
        payPwd: '资金密码'
      },
      status: {
        set: '已设置',
        notSet: '未设置'
      },
      button: {
        logout: '退出'
      },
      toast: {
        payPwdTip: '资金密码已设置，如需修改请联系客服'
      },
      loginPwd: {
        title: '修改登录密码',
        save: '保存',
        oldPassword: '原密码',
        oldPasswordPlaceholder: '请输入原密码',
        newPassword: '新密码',
        newPasswordPlaceholder: '请输入新密码',
        confirmPassword: '确认密码',
        confirmPasswordPlaceholder: '请再次输入新密码',
        enterOld: '请输入原密码',
        enterNew: '请输入新密码',
        confirmNew: '请再次输入新密码',
        notMatch: '两次输入的密码不一致',
        toast: {
          success: '修改成功',
          error: '修改失败'
        }
      }
    },
    profile: {
      title: '个人资料',
      avatar: '头像',
      name: {
        label: '真实姓名',
        notSet: '未设置',
        required: '请输入姓名',
        placeholder: '请输入真实姓名',
        notice: '为了账户安全，请确保真实姓名与绑定银行卡姓名一致',
        toast: {
          duplicate: '请勿重复设置！',
          success: '设置成功'
        }
      },
      gender: {
        label: '性别',
        unknown: '未知',
        male: '男',
        female: '女',
        title: '设置性别',
        save: '保存',
        toast: {
          success: '设置成功',
          duplicate: '请勿重复设置'
        }
      },
      bindInfo: {
        label: '绑定信息',
        bound: '已绑定',
        notBound: '未绑定',
        title: '绑定信息'
      },
      toast: {
        headerSuccess: '头像修改成功',
        headerError: '头像修改失败'
      },
      headerImg: {
        title: '选择头像',
        confirm: '确认',
        cancel: '取消'
      }
    },
    withdraw: {
      title: '提现',
      amount: '提现金额',
      all: '全部',
      balance: '余额', 
      button: '提现',
      recordBtn: '提现记录',
      limitInfo: {
        title: '额度说明',
        tip1: '单笔额度{min}，{max}',
        tip2: '每日最多提现{num}',
        tip3: '到账时间：一般5分钟内到账，最快2分钟内到账',
        min: '最低：',
        max: '最高：',
        times: '次'
      },
      toast: {
        enterAmount: '请输入正确金额',
        error: '提现失败',
        success: '提现成功'
      },
      record: {
        title: '提现记录',
        empty: '暂无记录',
        status: {
          success: '成功',
          pending: '处理中',
          failed: '失败'
        },
        amount: '金额',
        desc: '说明',
        submitTime: '提交时间',
        auditTime: '审核时间',
        refresh: {
          loading: '加载中...',
          pulling: '下拉刷新...',
          loosing: '释放刷新',
          success: '刷新成功'
        }
      }
    },
    bank: {
      title: '收款息',
      addCard: '加收款卡',
      info: {
        bankInfo: '银行信息',
        name: '姓名',
        cardNumber: '卡号'
      },
      tip: '提示: 绑定大型商业银行，需修改请联系在线客服',
      bind: {
        title: '绑定银行卡',
        tip: '请绑定大型商业银行',
        name: '真实姓名',
        namePlaceholder: '请输入真实姓名',
        cardNumber: '卡号',
        cardNumberPlaceholder: '请输入实际卡号',
        bank: '开户银行',
        bankPlaceholder: '请选择银行',
        button: '绑定银行卡',
        notice: '尊敬的用户，为了资金安全，请绑定您的真实姓名和提现密码。如果姓名与开户名不一致将无法提现',
        toast: {
          enterName: '请输入姓名！',
          enterCardNumber: '请输入银行卡号！',
          selectBank: '请选择银行',
          success: '绑定成功',
          enterComplete: '请输入完整信息',
          setBankFirst: '请先设置银行卡'
        },
        picker: {
          confirm: '确认',
          cancel: '取消'
        },
        payPassword: {
          title: '请输入资金密码',
          confirmTitle: '请再次输入资金密码'
        }
      }
    },
    report: {
      title: '个人报表',
      profit: {
        title: '利润额'
      },
      details: {
        title: '盈利计算公式：刷票余额 - 打榜金额',
        betAmount: '打榜金额',
        validAmount: '充值金额',
        profit: '提现金额'
      },
      toast: {
        error: '获取数据失败'
      }
    },
    gameRecord: {
      title: '游戏记录',
      empty: '暂无记录',
      list: {
        time: '时间',
        gameName: '游戏名称',
        betAmount: '打榜金额',
        profit: '盈亏',
        status: {
          win: '赢',
          lose: '输'
        },
        details: '详情',
        sexy: '性感',
        sweet: '甜美',
        mature: '成熟',
        pure: '清纯',
        paymentTime: '支付时间'
      },
      refresh: {
        loading: '加载中...',
        pulling: '下拉刷新...',
        loosing: '释放刷新',
        success: '刷新成功'
      },
      detail: {
        title: '游戏详情',
        period: '期号',
        time: '时间',
        betAmount: '打榜金额',
        betType: '注类型',
        result: '刷票',
        status: {
          title: '状态',
          win: '中奖',
          lose: '未中奖',
          pending: '待开奖'
        },
        winAmount: 'PK金额',
        profit: '盈亏',
        odds: '赔率',
        type: {
          sexy: '性感',
          sweet: '甜美',
          mature: '成熟',
          pure: '清纯'
        }
      },
      toast: {
        error: '获取数据失败'
      },
      status: {
        pending: '待刷票',
        win: '已刷票',
        lose: '未刷票'
      }
    },
    notice: {
      title: '系统公告',
      empty: '暂无公告',
      refresh: {
        loading: '加载中...',
        pulling: '下拉刷新...',
        loosing: '释放刷新',
        success: '刷新成功'
      }
    },
    service: {
      title: '在线客服',
      contact: '联系',
      available: '7x24小时在线服务', 
      defaultName: '蜜桃娱乐',
      disabled: '功能未启用'
    },
    loginHint: '登录后享受更多服务！'
  }
} 